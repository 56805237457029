/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";
import Icon from "react-icons-kit";
import { basic_world } from "react-icons-kit/linea/basic_world";
import { basic_smartphone } from "react-icons-kit/linea/basic_smartphone";
import { basic_mail } from "react-icons-kit/linea/basic_mail";
import { basic_compass } from "react-icons-kit/linea/basic_compass";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import Select from "react-select";

// custom styles
const styles = {
  menuList: (base) => ({
    ...base,
    maxHeight: "250px",
    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#e1e1e9",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#b1b1b9",
    },
  }),
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? "1px solid #007C16" : "1px solid #cccccc",
    boxShadow: state.isFocused ? "0px 0px 1px #007C16" : "none",
    "&:hover": {
      border: "1px solid #007C16",
      boxShadow: "0px 0px 1px #007C16",
    },
  }),
  option: (base, { isSelected, isFocused }) => ({
    ...base,
    backgroundColor: isSelected
      ? "#007C16"
      : isFocused
      ? "rgba(0, 124, 22, 0.2)"
      : base.backgroundColor,
    color: isSelected ? "white" : base.color,
    "&:active": {
      backgroundColor: isSelected ? "#007C16" : "rgba(0, 124, 22, 0.2)",
    },
  }),
};

const CustomTextField = styled(TextField)`
  & label.Mui-focused {
    color: #007c16;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid;
      border-color: #007c16;
    }
  }
  &:hover {
    & label {
      color: #007c16;
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-color: #007c16;
      }
    }
  }
`;

const CharityDetails = ({
  organizationDetails,
  charityDetails,
  donationAmount,
  setDonationAmount,
  amountsWithoutZero,
  setDonationModal,
  currentQuery,
  quantity,
  setQuantity,
}) => {
  // selector btns
  const [currentStep, setCurrentStep] = useState(
    charityDetails?.banner ? "banner" : "description"
  );

  // update amount
  useEffect(() => {
    if (charityDetails?.amounts?.length > 0 && amountsWithoutZero.length > 0) {
      if (charityDetails.amounts.includes(0)) {
        setDonationAmount(charityDetails.minimumAmount.toString());
      } else {
        setDonationAmount(amountsWithoutZero[0].toString());
      }
    }
  }, [charityDetails, amountsWithoutZero]);

  // handle donation amount
  const handleDonationAmount = (value) => {
    if (value) {
      setDonationAmount(value);
    } else {
      setDonationAmount("");
    }
  };

  // donation submit
  const handleDonationSubmit = () => {
    // extra layer of security validation
    if (charityDetails.enableQuantity) {
      // Extra layer of security validation
      const parsedQuantity = parseInt(quantity, 10);
      if (
        !isNaN(parsedQuantity) &&
        parsedQuantity >= 1 &&
        parsedQuantity <= 999
      ) {
        setDonationModal(true);
      }
    } else {
      if (
        parseFloat(donationAmount) >= parseFloat(charityDetails.minimumAmount)
      ) {
        setDonationModal(true);
      }
    }
  };

  // translation
  const { t } = useTranslation();

  // navigate
  const navigate = useNavigate();

  // location
  const location = useLocation();

  // full path
  const fullPath = location.pathname;

  // go back
  const handleGoBack = () => {
    // Extract the base route by splitting the path and taking the first segment
    const segments = fullPath.split("/");
    const baseRoute = `/${segments[1]}`;

    // Navigate to the base route
    if (currentQuery) {
      navigate(baseRoute + currentQuery, { replace: true });
    } else {
      navigate(baseRoute, { replace: true });
    }
  };

  // percentage
  const [percentage, setPercentage] = useState(null);
  const [width, setWidth] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState(null);
  useEffect(() => {
    if (charityDetails) {
      const calculatedPercentage = (
        (charityDetails.raisedAmount / charityDetails.targetAmount) *
        100
      ).toFixed(2);
      const calculatedWidth =
        calculatedPercentage >= 100 || !isFinite(calculatedPercentage)
          ? "100%"
          : `${calculatedPercentage}%`;
      const calculatedBackgroundColor = "#007c16";

      setPercentage(calculatedPercentage);
      setWidth(calculatedWidth);
      setBackgroundColor(calculatedBackgroundColor);
    }
  }, [charityDetails]);

  // handle quantity
  const handleQuantity = (value) => {
    // Allow only numbers between 1 and 999
    if (/^\d+$/.test(value) && value >= 1 && value <= 999) {
      setQuantity(value);
    } else if (value === "") {
      setQuantity(""); // Allow clearing the input
    }
  };

  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className="home-content">
      {organizationDetails && charityDetails && (
        <div className="cards-container single-card">
          <div className="custom-card single-card">
            <header className="card-header">
              <div className="card-leftside single-card">
                <div className="card-logo">
                  <img src={charityDetails.icon.filename} alt="card-logo" />
                </div>
                <div className="title-and-city-country">
                  <div className="organization-name">{charityDetails.name}</div>
                  <div className="city-and-country single-card">
                    {organizationDetails.name}
                  </div>
                </div>
              </div>
              {/* render enable quantity or amount */}
              <div
                className={`donation-box ${
                  charityDetails.enableQuantity ? "added-width" : ""
                }`}
              >
                {charityDetails.enableQuantity ? (
                  // enable quantity
                  <>
                    <MDBRow>
                      <MDBCol className="custom-quantity-col col-12 col-md-3 mb-2 mb-md-0">
                        <div className="donation-text">
                          {t("CampaignDetails.cost_per_unit")}
                        </div>
                        <div className="display-minimum-amount">
                          {organizationDetails.currencySymbol}
                          {charityDetails.minimumAmount}
                        </div>
                      </MDBCol>

                      <MDBCol className="custom-quantity-col col-12 col-md-3 mb-2 mb-md-0">
                        <div className="donation-text">
                          {t("CampaignDetails.enter_quantity")}
                        </div>
                        <CustomTextField
                          type="number"
                          variant="outlined"
                          fullWidth
                          autoComplete="off"
                          size="small"
                          value={quantity}
                          onChange={(e) => handleQuantity(e.target.value)}
                          inputMode="numeric"
                          step="1"
                          title=""
                          required
                          placeholder={t("CampaignDetails.enter_quantity")}
                          helperText={
                            quantity !== "" &&
                            (parseInt(quantity) < 1 || parseInt(quantity) > 999)
                              ? `${t("CampaignDetails.quantity_error_msg")}`
                              : ""
                          }
                          error={
                            quantity !== "" &&
                            (parseInt(quantity) < 1 || parseInt(quantity) > 999)
                          }
                        />
                      </MDBCol>

                      <MDBCol className="custom-quantity-col col-12 col-md-3 mb-2 mb-md-0">
                        <div className="donation-text">
                          {t("DonationModal.total_amount")}
                        </div>
                        <div className="display-minimum-amount">
                          {organizationDetails.currencySymbol}
                          {parseFloat(charityDetails.minimumAmount) * quantity}
                        </div>
                      </MDBCol>

                      <MDBCol className="custom-quantity-col col-12 col-md-3 mb-2 mb-md-0">
                        <div className="donation-text hidden">a</div>
                        <MDBBtn
                          type="button"
                          className="quantity-donate-btn"
                          onClick={handleDonationSubmit}
                          disabled={
                            parseInt(quantity) < 1 ||
                            parseInt(quantity) > 999 ||
                            quantity === ""
                          }
                        >
                          {charityDetails.donationCampaign ? (
                            <>{t("CampaignDetails.donate")}</>
                          ) : (
                            <>{t("CampaignDetails.pay")}</>
                          )}
                        </MDBBtn>
                      </MDBCol>
                    </MDBRow>
                  </>
                ) : (
                  // render amount box
                  <>
                    <div className="donation-text">
                      {charityDetails.donationCampaign ? (
                        <>{t("CampaignDetails.enter_donation_amount")}</>
                      ) : (
                        <>{t("CampaignDetails.enter_amount")}</>
                      )}
                      <span
                        className="minimum-amount-msg"
                        style={{ marginLeft: "10px" }}
                      >
                        {t("CampaignDetails.minimum_amount")}{" "}
                        {organizationDetails.currencySymbol}
                        {charityDetails.minimumAmount}
                      </span>
                    </div>
                    <div className="donation-form">
                      <div className="input-and-btn-div">
                        {charityDetails?.amounts.includes(0) ? (
                          <Autocomplete
                            fullWidth
                            freeSolo
                            options={amountsWithoutZero.map((amount) =>
                              amount.toString()
                            )}
                            getOptionLabel={(option) => option.toString()}
                            value={donationAmount}
                            onChange={(e, value) => {
                              if (isNaN(value) || parseFloat(value) < 0) {
                                value = "0";
                              }
                              handleDonationAmount(value);
                            }}
                            onKeyDown={(e) => {
                              const parts = e.target.value.split(".");

                              // Allow numeric keys, Backspace, Delete, navigation keys, and decimal point
                              if (
                                !/[0-9]/.test(e.key) &&
                                ![
                                  "Backspace",
                                  "Delete",
                                  "ArrowLeft",
                                  "ArrowRight",
                                  "ArrowUp",
                                  "ArrowDown",
                                  ".",
                                ].includes(e.key)
                              ) {
                                e.preventDefault();
                              }
                              // Prevent typing more than two decimal places
                              else if (
                                e.key !== "Backspace" &&
                                parts.length > 1 &&
                                parts[1].length >= 2 &&
                                /[0-9]/.test(e.key)
                              ) {
                                e.preventDefault();
                              }
                              // Prevent typing second decimal point
                              else if (
                                e.key === "." &&
                                e.target.value.includes(".")
                              ) {
                                e.preventDefault();
                              }
                            }}
                            renderOption={(props, option) => {
                              return (
                                <div {...props} className="list-div">
                                  {option}
                                </div>
                              );
                            }}
                            renderInput={(params) => (
                              <CustomTextField
                                {...params}
                                variant="outlined"
                                fullWidth
                                size="small"
                                value={donationAmount}
                                onChange={(e) => {
                                  let newValue = e.target.value;
                                  if (
                                    isNaN(newValue) ||
                                    parseFloat(newValue) < 0
                                  ) {
                                    newValue = "0";
                                  }
                                  handleDonationAmount(newValue);
                                }}
                                onKeyDown={(e) => {
                                  const parts = e.target.value.split(".");

                                  // Allow numeric keys, Backspace, Delete, navigation keys, and decimal point
                                  if (
                                    !/[0-9]/.test(e.key) &&
                                    ![
                                      "Backspace",
                                      "Delete",
                                      "ArrowLeft",
                                      "ArrowRight",
                                      "ArrowUp",
                                      "ArrowDown",
                                      ".",
                                    ].includes(e.key)
                                  ) {
                                    e.preventDefault();
                                  }
                                  // Prevent typing more than two decimal places
                                  else if (
                                    e.key !== "Backspace" &&
                                    parts.length > 1 &&
                                    parts[1].length >= 2 &&
                                    /[0-9]/.test(e.key)
                                  ) {
                                    e.preventDefault();
                                  }
                                  // Prevent typing second decimal point
                                  else if (
                                    e.key === "." &&
                                    e.target.value.includes(".")
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                                placeholder={t("DonationModal.amount")}
                                helperText={
                                  donationAmount !== "" &&
                                  parseFloat(donationAmount) <
                                    parseFloat(charityDetails.minimumAmount)
                                    ? `${t(
                                        "CampaignDetails.amount_error_msg"
                                      )} ${charityDetails.minimumAmount}`
                                    : ""
                                }
                                error={
                                  donationAmount !== "" &&
                                  parseFloat(donationAmount) <
                                    parseFloat(charityDetails.minimumAmount)
                                }
                              />
                            )}
                          />
                        ) : (
                          <div style={{ width: "100%" }}>
                            {amountsWithoutZero.length > 0 && (
                              <Select
                                styles={styles}
                                required
                                menuPlacement="bottom"
                                value={amountsWithoutZero
                                  .map((amount) => ({
                                    label: amount.toString(),
                                    value: amount.toString(),
                                  }))
                                  .find(
                                    (option) => option.value === donationAmount
                                  )}
                                onChange={(option) =>
                                  setDonationAmount(option.value)
                                }
                                options={amountsWithoutZero.map((amount) => ({
                                  label: amount.toString(),
                                  value: amount.toString(),
                                }))}
                              />
                            )}
                          </div>
                        )}

                        <MDBBtn
                          type="button"
                          className="donate-btn"
                          onClick={handleDonationSubmit}
                          disabled={
                            parseFloat(donationAmount) <
                              parseFloat(charityDetails.minimumAmount) ||
                            donationAmount === ""
                          }
                        >
                          {charityDetails.donationCampaign ? (
                            <>{t("CampaignDetails.donate")}</>
                          ) : (
                            <>{t("CampaignDetails.pay")}</>
                          )}
                        </MDBBtn>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </header>
            {charityDetails.targetAmount > 0 && (
              <>
                {width && backgroundColor && (
                  <div className="campaign-details-progress-div">
                    <div
                      className="progress-bar"
                      style={{
                        width,
                        background: backgroundColor,
                      }}
                    ></div>
                  </div>
                )}
              </>
            )}

            {charityDetails.targetAmount > 0 && (
              <div className="campaign-collection">
                <div className="collection-box">
                  <h4>
                    {organizationDetails.currencySymbol}
                    {charityDetails.raisedAmount.toFixed(2)}
                  </h4>
                  <h6>{t("CampaignDetails.raised")}</h6>
                </div>

                <div className="collection-box">
                  {percentage && (
                    <>
                      <h4>
                        {percentage >= 100 || !isFinite(percentage)
                          ? 100
                          : percentage}
                        %
                      </h4>
                      <h6>{t("CampaignDetails.collection")}</h6>
                    </>
                  )}
                </div>
                <div className="collection-box last">
                  <h4>
                    {organizationDetails.currencySymbol}
                    {charityDetails.targetAmount.toFixed(2)}
                  </h4>
                  <h6>{t("CampaignDetails.target")}</h6>
                </div>
              </div>
            )}

            <div className="campaigns-or-contact">
              {charityDetails.banner && (
                <button
                  type="button"
                  onClick={() => {
                    setCurrentStep("banner");
                  }}
                  className={`selector-btns${
                    currentStep === "banner" ? " active" : ""
                  }`}
                >
                  {t("CampaignDetails.banner")}
                </button>
              )}
              <button
                type="button"
                onClick={() => {
                  setCurrentStep("description");
                }}
                className={`selector-btns${
                  currentStep === "description" ? " active" : ""
                }`}
              >
                {t("CampaignDetails.description")}
              </button>
              <button
                type="button"
                onClick={() => {
                  setCurrentStep("contact");
                }}
                className={`selector-btns${
                  currentStep === "contact" ? " active" : ""
                }`}
              >
                {t("CampaignDetails.contact")}
              </button>
            </div>
            {currentStep === "banner" && (
              <div
                className="banner-img-container"
                style={{
                  margin: "20px auto",
                  background: isLoaded ? "transparent" : "#f7f7f7",
                }}
              >
                <img
                  src={charityDetails.banner}
                  alt="banner"
                  onLoad={() => {
                    setTimeout(() => {
                      setIsLoaded(true);
                    }, 500);
                  }}
                  style={{
                    display: isLoaded ? "block" : "none",
                  }}
                />
              </div>
            )}

            {currentStep === "description" && (
              <div className="campaign-msg-and-btn-div">
                <div className="campaign-msg">{charityDetails.description}</div>
              </div>
            )}

            {currentStep === "contact" && (
              <div className="contact-box">
                <h3 className="get-in-touch-heading">
                  {t("CampaignDetails.get_in_touch")}
                </h3>
                <div className="contact-info-box">
                  {/* name */}
                  <h6>{organizationDetails.name}</h6>
                  {/* streetAddress and unit no */}
                  <div className="p-row">
                    <div>
                      <Icon icon={basic_compass} size={16} />
                    </div>
                    <div className="p-row-text">
                      {organizationDetails.streetAddress}{" "}
                      {organizationDetails.unitNumber}
                    </div>
                  </div>
                  {/* city province and postal code */}
                  <div className="p-row">
                    <div className="empty-div" />
                    <div className="p-row-text">
                      {organizationDetails.city}{" "}
                      {organizationDetails.provinceState}{" "}
                      {organizationDetails.postalZipCode}
                    </div>
                  </div>
                  {/* country */}
                  <div className="p-row">
                    <div className="empty-div" />
                    <div className="p-row-text">
                      {organizationDetails.country}
                    </div>
                  </div>
                  {/* phone */}
                  <div className="p-row">
                    <div>
                      <Icon icon={basic_smartphone} size={16} />
                    </div>
                    <div className="p-row-text">
                      {organizationDetails.phone}
                    </div>
                  </div>
                  {/* email */}
                  <div className="p-row" style={{ overflow: "hidden" }}>
                    <div>
                      <Icon icon={basic_mail} size={16} />
                    </div>
                    <div className="p-row-text">
                      {organizationDetails.email ? (
                        <a href={`mailto:${organizationDetails.email}`}>
                          {organizationDetails.email}
                        </a>
                      ) : (
                        <span>{t("Content.not_available")}</span>
                      )}
                    </div>
                  </div>
                  {/* website */}
                  <div className="p-row" style={{ overflow: "hidden" }}>
                    <div>
                      <Icon icon={basic_world} size={16} />
                    </div>
                    <div className="p-row-text">
                      {organizationDetails.website ? (
                        <a href={organizationDetails.website} target="blank">
                          {organizationDetails.website}
                        </a>
                      ) : (
                        <span>{t("Content.not_available")}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <button
              type="button"
              className="go-back-to-campaign-selection-btn"
              onClick={handleGoBack}
              style={{
                background: "#696a64",
                color: "#fff",
                borderRadius: "4px",
              }}
            >
              {t("DonationModal.back")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CharityDetails;
